<template>
  <v-card class="pa-6">
    <v-expand-transition>
      <div class="d-flex align-center justify-space-between" v-if="!campaignPlan.expanded">
        <div class="d-flex">
          <div class="plan-icon d-flex justify-center align-center mr-3">
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.9064 3.50281C12.2058 2.58155 13.5091 2.58155 13.8085 3.50281L15.3273 8.17672C15.4612 8.58871 15.8451 8.86765 16.2783 8.86767L21.1928 8.86785C22.1615 8.86789 22.5643 10.1074 21.7806 10.6768L17.8048 13.5657C17.4543 13.8203 17.3077 14.2716 17.4415 14.6836L18.96 19.3577C19.2593 20.279 18.2049 21.045 17.4212 20.4757L13.4452 17.5872C13.0947 17.3326 12.6201 17.3326 12.2697 17.5872L8.29364 20.4757C7.50994 21.045 6.45552 20.279 6.75482 19.3577L8.27331 14.6836C8.40716 14.2716 8.26051 13.8203 7.91006 13.5657L3.93425 10.6768C3.15059 10.1074 3.55335 8.86789 4.52203 8.86785L9.43653 8.86767C9.86973 8.86765 10.2537 8.58871 10.3875 8.17672L11.9064 3.50281Z" stroke="#514A41" stroke-width="2"/>
            </svg>
          </div>
          <div class="plan--info">
            <div class="plan--name">{{ campaignPlan.planName }}</div>
            <div class="d-flex align-center">
              <span class="plan--amount">&#8358;{{ $numeral(campaignPlan.planCost).format('0,0') }}</span>
              <v-icon>mdi-circle-small</v-icon>
              <span>{{campaignPlan.merch.length}} Merch</span>
            </div>
          </div>
        </div>
        <div class="edit-icon">
          <v-btn icon @click="emitChange('expanded', true)">
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.1251 4.3084L21.8322 3.6013V3.6013L21.1251 4.3084ZM7.35742 21.6117V22.6117C7.62264 22.6117 7.87699 22.5063 8.06453 22.3188L7.35742 21.6117ZM3.85742 21.6117H2.85742C2.85742 22.1639 3.30514 22.6117 3.85742 22.6117V21.6117ZM3.85742 18.0406L3.15032 17.3335C2.96278 17.521 2.85742 17.7754 2.85742 18.0406H3.85742ZM18.2967 5.01551C18.8825 4.42973 19.8322 4.42973 20.418 5.01551L21.8322 3.6013C20.4654 2.23446 18.2493 2.23446 16.8825 3.6013L18.2967 5.01551ZM20.418 5.01551C21.0038 5.6013 21.0038 6.55105 20.418 7.13683L21.8322 8.55105C23.1991 7.18421 23.1991 4.96813 21.8322 3.6013L20.418 5.01551ZM20.418 7.13683L6.65032 20.9045L8.06453 22.3188L21.8322 8.55105L20.418 7.13683ZM7.35742 20.6117H3.85742V22.6117H7.35742V20.6117ZM16.8825 3.6013L3.15032 17.3335L4.56453 18.7477L18.2967 5.01551L16.8825 3.6013ZM2.85742 18.0406V21.6117H4.85742V18.0406H2.85742ZM15.3825 6.51551L18.918 10.051L20.3322 8.63683L16.7967 5.1013L15.3825 6.51551Z" fill="#514A41"/>
            </svg>
          </v-btn>
        </div>
      </div>
    </v-expand-transition>
    <v-expand-transition>
      <div v-show="campaignPlan.expanded">
        <v-form>
          <div>
            <label>Plan Name</label>
            <v-text-field @input="emitChange('planName', $event)" outlined />
          </div>
          <div>
            <label>Plan Cost</label>
            <currency-field @input="emitChange('planCost', $event)" v-model="campaignPlan.planCost" outlined />
          </div>
          <div class="select--box">
            <label>Add Merch</label>
            <v-combobox item-text="name" item-value="id" :menu-props="{attach: '.select--box', contentClass: 'content'}" @change="emitChange('merch', $event)" :value="campaignPlan.merch" hide-details multiple :items="merchs" outlined>
              <template #append>
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.8574 9.57617L12.8574 16.5762L5.85742 9.57617" stroke="#514A41" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </template>
              <template #selection>
                <span class="selection__text">Select</span>
              </template>
            </v-combobox>
            <div class="mt-4">
              <v-chip
                v-for="(_m, i) in campaignPlan.merch"
                :key="i"
                close
                @click:close="unSelectItem(_m)"
                close-icon="mdi-close"
                color="#F9F4EE"
                class="mr-1 mb-2"
                >{{ _m.name }}</v-chip>
            </div>
          </div>
        </v-form>
        <div class="d-flex mt-6 card-actions">
          <v-btn rounded left depressed height="48" width="171" @click="add">
            <svg class="mr-1" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3574 3.57617C10.9097 3.57617 11.3574 4.02389 11.3574 4.57617V9.57617H16.3574C16.9097 9.57617 17.3574 10.0239 17.3574 10.5762C17.3574 11.1285 16.9097 11.5762 16.3574 11.5762H11.3574V16.5762C11.3574 17.1285 10.9097 17.5762 10.3574 17.5762C9.80514 17.5762 9.35742 17.1285 9.35742 16.5762V11.5762H4.35742C3.80514 11.5762 3.35742 11.1285 3.35742 10.5762C3.35742 10.0239 3.80514 9.57617 4.35742 9.57617L9.35742 9.57617V4.57617C9.35742 4.02389 9.80514 3.57617 10.3574 3.57617Z" fill="#00050D"/>
            </svg>
            Add New Plan
          </v-btn>
          <v-btn rounded left depressed class="ml-3" height="48" width="171" @click="$emit('remove', campaignPlan.id)">
            <svg class="mr-1" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.3574 7.57617L18.4901 19.7187C18.4153 20.7653 17.5445 21.5762 16.4952 21.5762H8.21966C7.17038 21.5762 6.2995 20.7653 6.22474 19.7187L5.35742 7.57617M10.3574 11.5762V17.5762M14.3574 11.5762V17.5762M15.3574 7.57617V4.57617C15.3574 4.02389 14.9097 3.57617 14.3574 3.57617H10.3574C9.80514 3.57617 9.35742 4.02389 9.35742 4.57617V7.57617M4.35742 7.57617H20.3574" stroke="#514A41" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Remove
          </v-btn>
        </div>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import CurrencyField from "@/components/CurrencyField";
export default {
  name: "CampaignPlanCard",
  components: { CurrencyField },
  data: () => ({
    merchs: [],
  }),
  props: {
    campaignPlan: Object
  },
  async created () {
    const { data } = await this.$axios(`/products/product`)
    console.log(data)
    this.merchs = data.map(({ name, id }) => ({ name, id }))
  },
  methods: {
    isEqual (planA, planB) {
      console.log(planB, planA)
      return Object.keys(planA).every(keyA => planA[keyA] === planB[keyA])
    },
    add () {
      this.$emit('add')
    },
    unSelectItem (merch_item) {
      this.emitChange('merch', this.campaignPlan.merch.filter(_merch => _merch.id !== merch_item.id))
    },
    emitChange (prop, val) {
      this.$emit('change', {
        ...this.campaignPlan,
        [prop]: val
      })
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep {
  &.v-card {
    background: #FFFFFF;
    /* Grey/200 */

    border: 1px solid #F4F3F3 !important;
    box-shadow: 0px 12px 24px rgba(173, 173, 173, 0.08), 0px 2px 4px rgba(173, 173, 173, 0.04) !important;
    border-radius: 20px !important;
  }

  .v-form {
    & > div {
      margin-bottom: 10px;

      label {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */


        color: #000000;
        margin-bottom: 4px;
      }
    }
  }

  .card-actions {
    .v-btn {
      background: #F4F3F3 !important;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */


      /* Neutral/Black */

      color: #00050D;
      letter-spacing: 0;
      text-transform: capitalize;
    }
  }

  .v-chip {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */


    /* Neutral/Black */

    color: #00050D;

    &:before {
      display: none;
    }
  }

  .selection__text {
    &:not(:first-child) {
      display: none;
    }

    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;

    /* Neutral/Black */

    color: #00050D;
  }

  .plan-icon {
    width: 40px;
    height: 40px;

    /* Brown/400 */

    background: #F9F4EE;
    border-radius: 100px;
  }

  .plan--name {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */


    color: #000000;

    &+div {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */


      /* Grey/400 */

      color: #9A9595;
    }
  }

  .v-select-list.v-list {
    border: 1px solid #F9F4EE !important;
    box-shadow: 0px 12px 24px rgba(173, 173, 173, 0.08), 0px 2px 4px rgba(173, 173, 173, 0.04) !important;
    border-radius: 16px !important;

    .v-list-item {
      &:before {
        display: none !important;
      }
    }
  }

  .content {
    border: 1px solid #F9F4EE !important;
    box-shadow: 0px 12px 24px rgba(173, 173, 173, 0.08), 0px 2px 4px rgba(173, 173, 173, 0.04) !important;
    border-radius: 16px !important;
  }
}
</style>
