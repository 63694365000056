<template>
  <div class="campaign-summary-wrap">
    <span class="sub-head mb-2">CONFIRM DETAILS</span>
    <h4 class="campaign--name">{{ stepsData.name }}</h4>
    <div class="target my-8">
      <span class="sub-head">TARGET</span>
      <div class="amount">&#8358;{{ $numeral(stepsData.targetAmount).format('0,0') }}</div>
    </div>
    <div class="plans-summary">
      <span class="sub-head">PLANS</span>
      <div>
        <div v-for="(plan, i) in stepsData.plans" :key="plan.id" class="d-flex justify-space-between py-4 plan-merch">
          <div>
            <div class="planName">{{ plan.planName }}</div>
            <div class="merch--list">{{ genMerchList(plan.merch) }}</div>
          </div>
          <div class="planCost">&#8358;{{$numeral(plan.planCost).format('0,0')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CampaignSummary",
  props: {
    stepsData: {
      type: Object
    }
  },
  methods: {
    genMerchList (list) {
      if (list.length === 1) return list[0].name
      return list.map((l, i) => {
        if (i === list.length - 1) return `and ${l.name}.`
        if (i === list.length - 2) return `${l.name} `
        return `${l.name}, `
      }).join("")
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep {
  .sub-head {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height */

    letter-spacing: 0.1em;
    text-transform: uppercase;

    /* Grey/400 */

    color: #9A9595;
  }

  .campaign--name {
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    /* or 100% */

    letter-spacing: -0.04em;

    /* Brown/500 */

    color: #514A41;
  }

  .target {
    .amount {
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      /* identical to box height, or 100% */

      letter-spacing: -0.02em;

      /* Neutral/Black */

      color: #00050D;
    }
  }

  .planName {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */


    /* Neutral/Black */

    color: #00050D;
  }

  .planCost {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */


    /* Neutral/Black */

    color: #00050D;
  }

  .merch--list {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */


    /* Grey/400 */

    color: #9A9595;
  }

  .plan-merch {
    border-bottom: 1px solid #F4F3F3;
  }
}
</style>
