<template>
  <div class="create-campaign-wrap">
    <landing-page-header />
    <div class="create-campaign-content mx-auto d-flex justify-center px-3">
      <v-card v-if="$vuetify.breakpoint.width > 790" width="300" height="190" class="tabs-wrapper mr-16">
        <v-tabs vertical v-model="step">
          <v-tab
            v-for="(tab, i) in tabs"
            :key="i"
            :class="[{'v-tab--passed': i < step}]"
            :disabled="i > step"
          >
            <div class="d-flex justify-space-between tw-h-full align-center tw-w-full">
              <div class="d-flex align-center">
                <span class="number-indicator d-flex justify-center align-center">{{ i + 1 }}</span>
                <span class="ml-3">{{ tab.name }}</span>
              </div>
              <v-icon>mdi-chevron-right</v-icon>
            </div>
          </v-tab>
        </v-tabs>
      </v-card>
      <div class="step-components">
        <div class="mb-6">
          <v-btn icon @click="back">
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5645 17.2833C10.174 17.6738 9.54084 17.6738 9.15032 17.2833L3.15032 11.2833C2.75979 10.8928 2.75979 10.2596 3.15032 9.86906L9.15032 3.86907C9.54084 3.47854 10.174 3.47854 10.5645 3.86907C10.9551 4.25959 10.9551 4.89275 10.5645 5.28328L6.27164 9.57617H17.8574C18.4097 9.57617 18.8574 10.0239 18.8574 10.5762C18.8574 11.1285 18.4097 11.5762 17.8574 11.5762L6.27164 11.5762L10.5645 15.8691C10.9551 16.2596 10.9551 16.8928 10.5645 17.2833Z" fill="#514A41"/>
            </svg>
          </v-btn>
        </div>
        <div class="step-description">
          <h4>{{ componentTitle }}</h4>
          <p>{{ componentMessage }}</p>
        </div>
        <v-slide-x-reverse-transition>
          <keep-alive>
            <component
              :is="comp"
              @change:date="stepsData.date = $event"
              @change:add-date="stepsData.addDate = $event"
              @change:name="stepsData.name = $event"
              @change:plans="stepsData.plans = $event"
              @change:target-amount="stepsData.targetAmount = $event"
              :steps-data="stepsData"
            />
          </keep-alive>
        </v-slide-x-reverse-transition>
        <v-btn depressed block rounded class="my-6" @click="actionNext" height="42px" color="primary">{{ step !== 3 ? 'Next' : 'Create Campaign' }}</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import CreateCampaignNameDate from "@/components/campaign/CreateCampaignNameDate";
import AddPlans from "@/components/campaign/AddPlans";
import SetTarget from "@/components/campaign/SetTarget";
import CampaignSummary from "@/components/campaign/CampaignSummary";
import LandingPageHeader from "@/components/LandingPageHeader";
import PreventsReload from "@/mixins/PreventsReload";
export default {
  name: "CreateCampaign",
  mixins: [PreventsReload],
  components: { LandingPageHeader, CreateCampaignNameDate, AddPlans, SetTarget, CampaignSummary },
  data: () => ({
    tabs: [
      { name: 'Create Campaign' },
      { name: 'Add Plans' },
      { name: 'Set Target' },
      { name: 'Confirm' }
    ],
    step: 0,
    stepsData: {
      name: '',
      date: null,
      addDate: false,
      plans: [],
      targetAmount: ''
    },
    loading: false,
  }),
  computed: {
    comp () {
      const comps = {
        0: 'create-campaign-name-date',
        1: 'add-plans',
        2: 'set-target',
        3: 'campaign-summary'
      }
      return comps[this.step]
    },
    componentTitle () {
      const titles = {
        0: 'Create Campaign',
        1: 'Add Plans',
        2: 'Set Target'
      }
      return titles[this.step]
    },
    componentMessage () {
      const messages = {
        0: '',
        1: 'You can create a maximum of 4 plans',
        2: 'You can create a maximum of 4 plans'
      }
      return messages[this.step]
    }
  },
  methods: {
    actionNext () {
      const {
        name,
        date,
        addDate,
        plans,
        targetAmount
      } = this.stepsData
      const actions = {
        0: () => {
          if (!name) {
            this.$displaySnackbar({ message: "Please enter a name for your campaign" })
            return
          }
          if (addDate && !date) {
            this.$displaySnackbar({ message: "Please enter a date if you wish to add an end date" })
            return
          }
          this.step++
        },
        1: () => {
          if (plans.some(plan => !plan.planName || !plan.planCost || plan.merch.length === 0)) {
            return this.$displaySnackbar({ message: "Please fill all the plan forms" })
          }
          this.step++
        },
        2: () => {
          if (!targetAmount) {
            return this.$displaySnackbar({ message: "Please fill in the target amount" })
          }
          this.step++
        },
        3: async () => {
          this.loading = true;
          try {
            const { data: { event_code, id } } = await this.$axios.post(`/events/event/`, {
              name,
              event_date: date,
              target: targetAmount.split(',').join(''),
              plans: plans.map(plan => ({ name: plan.planName, cost: plan.planCost.split(',').join(''), product_ids: plan.merch.map(m => m.id) }))
            })
            await this.$router.push({
              name: 'CampaignSplash',
              query: {
                code: event_code,
                id
              }
            })
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false;
          }
        }
      }
      actions[this.step]?.()
    },
    back () {
      if (this.step !== 0) {
        this.step--
      } else this.$router.back()
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep {
  .create-campaign-content {
    max-width: 900px;

    .number-indicator {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: #F9F4EE;
    }
  }

  .v-tabs-slider-wrapper {
    display: none !important;
  }

  .v-tab {
    .v-ripple__container {
      display: none !important;
    }

    text-transform: capitalize;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0;
    /* identical to box height, or 171% */


    /* Brown/500 */

    color: #514A41 !important;
    padding: 16px !important;

    border-bottom: 1px solid #F4F3F3;

    &:hover {
      background: white !important;
    }

    &:before {
      display: none !important;
    }
  }

  .v-tab--active, .v-tab--passed {
    color: #514A41 !important;
    .number-indicator {
      background: #FF7A01;
      color: white;
    }
  }

  .tabs-wrapper {
    background: #FFFFFF;
    /* Grey/200 */

    border: 1px solid #F4F3F3 !important;
    box-shadow: 0px 12px 24px rgba(173, 173, 173, 0.08), 0px 2px 4px rgba(173, 173, 173, 0.04) !important;
    border-radius: 8px !important;
  }

  .step-components {
    width: 400px;
  }

  .step-description {
    h4 {
      font-weight: 700;
      font-size: 2em;
      line-height: 32px;
      /* identical to box height, or 100% */

      letter-spacing: -0.04em;

      /* Brown/500 */

      color: #514A41;
      margin-bottom: 8px;
    }

    p {
      font-weight: 400;
      font-size: 1.125em;
      line-height: 24px;
      /* identical to box height, or 133% */


      /* Grey/400 */

      color: #9A9595;
    }
  }
}
</style>
