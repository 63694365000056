<template>
  <div class="create-campaign-name-date-wrap px-2">
    <div>
      <label>Name</label>
      <v-text-field v-model="name" hide-details outlined class="mt-2" />
    </div>
    <div style="width: 108px">
      <v-checkbox v-model="addDate" label="Add Date" />
    </div>
    <v-slide-y-transition>
      <div v-if="addDate" class="date-block">
        <span class="d-block mb-n2">Select Date</span>
        <small>People can no longer buy merch after this period</small>
        <v-date-picker v-model="date" color="#00050D" full-width class="mt-2" no-title first-day-of-week="1" />
      </div>
    </v-slide-y-transition>
  </div>
</template>

<script>
export default {
  name: "CreateCampaignNameDate",
  data: () => ({
    addDate: false,
    date: null,
    name: ''
  }),
  watch: {
    addDate (val) {
      this.$emit('change:add-date', val)
    },
    date (val) {
      this.$emit('change:date', val)
    },
    name (val) {
      this.$emit('change:name', val)
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep {
  .theme--light.v-picker__body {
    background: #FAFAFA !important;
  }

  .v-date-picker-table {
    table {
      tbody {
        tr {
          td {
            button {
              font-weight: 400 !important;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              letter-spacing: 0.5px;
            }
          }
        }
      }
    }
  }

  .v-date-picker-header {
    button {
      i {
        font-size: 18px !important;
      }
    }

    .v-date-picker-header__value {
      button {
        font-weight: 500;
        font-size: 14px;
        font-family: Roboto, sans-serif !important;
        line-height: 24px;
        /* identical to box height, or 171% */

        letter-spacing: 0.1px;

        /* 00. On Surface / Medium Emphasis */

        color: rgba(0, 0, 0, 0.6) !important;
      }
    }
  }

  .v-input--checkbox {
    .v-label {
      font-family: 'DM Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */

      letter-spacing: 0.02em;

      color: #000000;
    }
  }

  .date-block {
    span.d-block {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */


      color: #000000;
    }

    small {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height, or 133% */

      letter-spacing: 0.01em;

      /* Brown/500 */

      color: #514A41;
    }
  }
}
</style>
