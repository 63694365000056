<template>
  <div class="currency--field-wrap">
    <v-text-field
      v-bind="{ ...$attrs, ...$props }"
      v-on="listeners"
      :value="formattedValue"
      outlined
      hide-details
    />
  </div>
</template>

<script>
export default {
  name: "CurrencyField",
  data: () => ({
    formattedValue: ''
  }),
  computed: {
    listeners () {
      return {
        ...this.$listeners,
        input: value => {
          value = value.replace(/[a-zA-Z]/gm, "").replace(/,/gm, "")
          if (!!value && /^[0-9]*$/gm.test(value)) {
            this.formattedValue = this.$numeral(value).format('0,0')
            this.$emit("input", this.formattedValue)
          }
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep {}
</style>
