<template>
  <div class="add-plans-wrap">
    <campaign-plan-card
      v-for="(plan, i) in plans"
      :key="plan.id"
      :campaign-plan="plan"
      @change="updateItem($event, i)"
      @add="addNewPlan"
      @remove="removePlan"
      class="mb-5"
      />
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'
import CampaignPlanCard from "@/components/campaign/CampaignPlanCard";
export default {
  name: "AddPlans",
  components: { CampaignPlanCard },
  data: () => ({
    plans: []
  }),
  created () {
    if (this.plans.length === 0) {
      this.addToPlan()
    }
    console.log(this.plans)
    this.updateItem(this.plans)
  },
  watch: {
    plans (plans) {
      this.updateParentData(plans)
    }
  },
  methods: {
    addToPlan () {
      if (this.plans.length === 4) {
        this.$displaySnackbar({ hint: "You cannot create more than four plans for a campaign" })
        return
      }
      this.plans.push({
        planName: '',
        planCost: '',
        merch: [],
        expanded: true,
        id: uuid()
      })
    },
    addNewPlan () {
      if (this.plans.some(plan => !plan.planName || !plan.planCost || plan.merch.length < 1)) {
        return this.$displaySnackbar({ message: "Kindly fill open plan before creating a new one" })
      }
      this.plans = this.plans.map(plan => ({...plan, expanded: false}))
      this.addToPlan()
    },
    updateItem (plan, index) {
      if (plan.expanded) {
        this.plans = this.plans.map(plan => ({...plan, expanded: false}))
      }
      this.plans[index] = plan
    },
    removePlan (id) {
      if (this.plans.length === 1) {
        return this.$displaySnackbar({ message: 'There must be at least a single plan' })
      }
      this.plans = this.plans.filter(plan => plan.id !== id)
    },
    updateParentData (plans) {
      this.$emit('change:plans', plans)
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep {

}
</style>
