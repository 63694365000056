<template>
  <div class="set-target-wrap">
    <div>
      <label>Amount</label>
      <currency-field @input="$emit('change:target-amount', $event)" />
    </div>
  </div>
</template>

<script>
import CurrencyField from "@/components/CurrencyField";
export default {
  name: "SetTarget",
  components: { CurrencyField }
};
</script>

<style scoped lang="scss">
::v-deep {
  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */


    color: #000000;
    margin-bottom: 4px;
  }
}
</style>
